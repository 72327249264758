<script>
import { VueTyper } from "vue-typer";
import {
  ArrowRightCircleIcon,
  ArrowUpIcon,
  EyeIcon,
  ArrowRightIcon,
  HeartIcon,
  BoldIcon,
  FeatherIcon,
  CodeIcon,
  MonitorIcon,
  UserCheckIcon,
  GitMergeIcon,
  SettingsIcon,
  VideoIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";
import countTo from "vue-count-to";
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";



/**
 * Index-landing-one component
 */
export default {
  data() {
    return {     featuresData: [
        {
          icon: "big-icon my-color-1 uil uil-file-search-alt", 
          title: "Research & Stratagy",
          description:
            " To ensure our digital marketing agency efforts are efficient and effective, and in-line with your long-term goals.",
        },
        {
          icon: "big-icon my-color-1 uil uil-airplay",
          title: "Increase Visibility",
          description:
            "Analyze your business to design and develop any type of stunning and creative asstes, that drive results for your business.",
        },
                {
          icon: "big-icon my-color-1 uil uil-process",
          title: "Grow Your Customer Base",
          description:
            "High search results for relevant keywords is one key way to ensure that your website remains top-of-mind for potential customers. ",
        },
        {
          icon: "big-icon my-color-1 uil uil-thumbs-up",
          title: "Maintain Online Presence",
          description:
            "Grow and engage your audience with effective SEO, social media strategy, build back-link to establish and maintain online presence. ",
        },
        {
          icon: "big-icon my-color-1 uil uil-rocket",
          title: "Boost Performance Media",
          description:
            "Stand out from the crowd with a targeted social media advertising, PPC Ads campaign that propels your message through the online noise.",
        },
        {
          icon: "big-icon my-color-1 uil uil-shield-check",
          title: "Proprietary Processes",
          description:
            "we surround ourselves with other leaders, and our education never stops. Our Proprietary Processes are time-tested and field proven.",
        },
      ],
      
      };
  },
  components: {
    Navbar,
    "vue-typer": VueTyper,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    EyeIcon,
    VideoIcon,
    ArrowRightIcon,
    ArrowRightCircleIcon,
    BoldIcon,
    FeatherIcon,
    CodeIcon,
    MonitorIcon,
    UserCheckIcon,
    GitMergeIcon,
    SettingsIcon,
    HeartIcon,
    countTo,
  },
};



</script>




<template>
  <div>
    <Navbar :nav-light="false" />

    





    <!-- Hero Start -->
    <section class="bg-half-260 pb-lg-0 pb-md-4 bg-primaryz d-table w-100 mt-40">

      
      <div class="bg-overlay" style="margin-bottom: 40px; background: url('images/background/karukaj-cover-1.png') center bottom;"></div>
        
    

      <br>



      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">

            <div class="card login_page shadow rounded border-0 px-2">
              <div class="card-body">

                <form class="login-form justify-content-center">
                  <div class="row">
                    <div class="col-md-12">

                      <h4 class="text-center font-weight-bold">Get Free Price Estimate</h4>
                      <p class=" text-left-padding-30 text-md-left">
                 
                 <span class="element kd-our-service">
                   <span v-b-tooltip.hover title="Karukaj is an ancient best digital marketing agency or web development company in Bangladesh near Dhaka.  
                   Also known as low-priced best eCommerce website development company in Bangladesh. Be around us!"></span><strong>Our Service:</strong> <vue-typer
                     :text="[
                       'eCommerce Web Development',
                       'Web Design and Development',
                       'WordPress Website Design',
                        'Branding/Positioning',
                        'Social Media Graphics Kit',
                        'Video Content Creation',
                     ]"
                   ></vue-typer>
                 </span>          
                </p>
                      


                      <iframe height="400" width="100%" frameborder="0" src="https://business.karukaj.com.bd/forms/quote/94a7dccf3fa8229fc290464e855691c3"  style="border:0"></iframe>

                    </div>
      

                  </div>
                </form>
              </div>
            </div>









          </div>
          <!--end col-->

 

        
              <div class="col-md-5 col-12 mt-4 pt-2 mt-sm-0 pt-sm-0 text-md-right">



                
              


          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->











    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 250"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M720 125L2160 0H2880V250H0V125H720Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->






   <!-- Feature Start -->
    <section class="section">


      <div class="container mt-lg-0">
            <div class="row">
          
                <div class="col-md-4">
                </div>
                <!--end col-->
                <div class="col-md-8">
                    <div class="section-title text-md-right">

                      <h4 class="title mb-4"><span class="font-weight900"> Karukaj</span>  <span v-b-tooltip.hover title=" Karukaj Digital is a business process outsourcing company in the current internet
                         marketing era. 
                        We've provided a constant presence in the ever-changing world for over a decade.
                      " class="my-color-1 font-weight-bold"> DIGITAL</span> | Business Storytelling</h4>







                        <p class="text-muted text-md-right">We express your praise digitally by empowering digital commerce and creating experiences that are initiative, engaging, and highly rewarding for ...</p>

                    </div>
                </div>
                <!--end col-->

            </div>
            <!--end row-->

        </div>
        <!--end container-->











      
      <div class="responsive-center container mt-4">

        <div class="row">
          <div
            class="col-md-4 col-12"
            v-for="(item, index) of featuresData"
            :key="index"
          >
            <div class="features mt-5">
              <div class="image position-relative d-inline-block">
                <i :class="`${item.icon} h1 my-color-1`"></i>
              </div>

              <div class="responsive-center content mt-4">
                <h5>{{ item.title }}</h5>
                <p class="text-muted mb-0">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->






    </section>
    <!--end section-->
    <!-- End -->

 













    <div class="container">
  
  <br>
  <div class="col-12 text-center">
                <div class="section-title mb-4">
                    <h4 class="title mb-4"> Let’s make <b>things happen</b></h4>
  
               
            <p class="text-muted para-desc mb-0 mx-auto">
           We’ve been in business for
           <span v-b-tooltip.hover title="Karukaj Digital is especially popular among the ancient best website design company in dhaka bangladesh since 2007. Currently, 
           launches as a full fledged digital marketing agency in Bangladesh." 
            class="text-muted" >over 13 years</span>, 
            where our creativity is your success that can provide everything you need to generate awareness, drive traffic, connect.
          </p>
  
  
  
            </div>
            </div>
            <!--end col-->
  
  
  
  
    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
  
        <div class="card features work-process bg-transparent process-arrow border-0 text-center" >
  
        
  
          <div class="icons rounded h1 text-center my-color-1 px-3">
            <img src="images/clipart/1-solution.png" class="avatar avatar-150x" alt="">
          </div>
  
          <div class="card-body">
            <h4 class="title text-dark font-weight-bold">1. Find the Solution you need</h4>
            <p class="responsive-small text-muted mb-0">
              <span v-b-tooltip.hover title="In addition to complete web design and development service in Bangladesh, 
              we also provide one-page web page design at low cost. " class="text-muted"> Compare prices,</span> portfolios, community recommendations, and more to select our Core IT solutions you're most excited to work with and then be ready to be.
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
  
      <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
        <div
          class="card features work-process bg-transparent process-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center my-color-1 px-3">
            <img src="images/clipart/2-brief.png" class="avatar avatar-150x" alt="">
          </div>
        
  
          <div class="card-body">
            <h4 class="title text-dark font-weight-bold">2. Supply your brief</h4>
            <p class="responsive-small text-muted mb-0">

              After payment confirmation, we then ask for a job description short list & content to review. We also want to know if you've any specific reference or custom request.

            </p>
          </div>
        </div>
      </div>
      <!--end col-->
  
      <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
        <div
          class="card features work-process bg-transparent d-none-arrow border-0 text-center"
        >
          <div class="icons rounded h1 text-center my-color-1 px-3">
            <img src="images/clipart/3-monitor.png" class="avatar avatar-150x" alt="">
  
          </div>
  
          <div class="card-body">
            <h4 class="title text-dark font-weight-bold">3. Begin to monitor each task</h4>
            <p class="responsive-small text-muted mb-0">
              Start watching the progress of the work where one or more experienced professionals will complete tasks and make revisions within agreed time frames.
  
  
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  
  
  </div>
  <!--end container-->
  
  
  
  
  





























<div class="container">
  <br>   <br> 
<div class="row align-items-center">
 <div class="col-12 text-center">
    <div class="section-title mb-4">
      <h4 class="title mb-4"><b>Core Solutions</b> We're Building</h4>


      <p class="text-muted">
        Apart from managing online <span v-b-tooltip.hover title="Karukaj is well-regarded as the best digital marketing agency in Bangladesh, 
        as well as the best website design company in Bangladesh" class="text-muted">business</span> we also provide some solutions to empower some entrepreneurs.
      


   


      </p>



    </div>
  </div>
  <!--end col-->







      <div class="col-lg-12">









        <div class="row" style="margin-right: 0px; margin-left: 0px;">


<!--Start Service Area-->                  
<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-01>
                <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/landing-page-online-marketing-strategy.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">
                        <h4 class="title mb-0 text-dark">Complete eCommerce Site</h4>
                        <p class="text-muted mb-0">Laravel eCommerce Solution</p>
                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-01" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="images/service/01-eCommerce-website-development-in-Bangladesh-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  


<!--Start Service Area-->                  
<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-02>
  
  <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/custom-web-development-company.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">
                        <h4 class="title mb-0 text-dark">Custom Business Website</h4>
                        <p class="text-muted mb-0">Business Portfolio Storytelling</p>
                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-02" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="images/service/02-Business-Website-Design-with-UI-UX-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  



<!--Start Service Area-->                  
<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-03>
  
  <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/icons8-web-design-64.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">
                        <h4 class="title mb-0 text-dark">WordPress Website Design</h4>
                        <p class="text-muted mb-0">Self-manageable Platform</p>
                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-03" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="images/service/03-WordPress-Website-Design-and-Redesign-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  


<!--Start Service Area-->                  
<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-05>
  
  <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/adaptive-web-development-company.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">
                      <h4 class="title mb-0 text-dark">Theme Re-Design Service</h4>
                        <p class="text-muted mb-0">For any type Website or Blogger</p>
                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-05" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="images/service/04-Custom-Web-Design-and-Development-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  


        <!--Start Service Area-->                  
        <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-04>
  
  <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/seo-marketing-strategy.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">
                      <h4 class="title mb-0 text-dark">On-Page or Off-Page SEO</h4>
                        <p class="text-muted mb-0">For any blog or website or shop</p>
                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-04" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="images/service/05-Effective-SEO-Marketing-Service-in-Bangladesh-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  













   

<!--Start Service Area-->                  
<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-06>
  <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/web-development-company.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">

                      <h4 class="title mb-0 text-dark">Social Media Graphics Set</h4>
                        <p class="text-muted mb-0"> Incredible Vector Post & Cover</p>

                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-06" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="images/service/06-Social-Media-Graphics-Kit-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  












   

<!--Start Service Area-->                  
<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-07>
  <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/video-online-marketing-strategy.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">
                      <h4 class="title mb-0 text-dark">Video Content Creation</h4>
                        <p class="text-muted mb-0">AE/PR Post & Promo Creation</p>
                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-07" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="/images/service/07-Animation-Video-Editing-Services-in-Bangladesh-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  











   

<!--Start Service Area-->                  
<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-08>
  <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/customer-online-marketing-strategy.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">
                        <h4 class="title mb-0 text-dark">Facebook Ads Management</h4>
                        <p class="text-muted mb-0">Run effecive ads on Facebook</p>


                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-08" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="images/service/09-PPC-Online-Advertising-Services-in-Bangladesh-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  







   

<!--Start Service Area-->                  
<div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">      
<div class="media contact-detail align-items-center">
<div class="media-body content">
<a href="javascript: void(0);" class="my-color-1" v-b-modal.modal-09>
  <div class="media key-feature align-items-center p-3 rounded shadow">
                   <img src="images/icon/brainstorm-online-marketing-strategy.png" class="avatar avatar-47x" alt="">
                    <div class="media-body ml-3">
                        <h4 class="title mb-0 text-dark">Pay-Per-Click Marketing</h4>
                        <p class="text-muted mb-0">Highly visible Google Ads & more</p>
                    </div>
                </div>
</a>
</div><!--end col-->                      
</div>

<b-modal hide-footer header-close-variant="white" header-class="border-0" content-class="border-0" id="modal-09" size="lg" centered body-class="p-0">
<div class="row justify-content-center align-items-center">
<div class="col-lg-6 col-md-6 order-1 order-md-2">
<img height="420" width="420" src="images/service/09-PPC-Online-Advertising-Services-in-Bangladesh-2.jpg" class="img-fluid" alt="" />
</div>

<div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
<iframe height="420" width="380" frameborder="0" src="https://business.karukaj.com.bd/forms/wtl/bc427978d270811c62e2bb601db5732b"  style="border:0"></iframe>
</div>
</div>
</b-modal>
</div>
<!--End Service Area-->  








    </div>



   
        </div>
        <!--end col-->


  <div class="col-12 mt-4 pt-2 text-center">

<span class="responsive-small text-muted font-weight-bold">and a whole lot more … every step to online success.</span> <br><br>  
  </div>
  <!--end col-->




</div>
<!--end row-->
</div>
<!--end container-->























  <div class="col-12 mt-4 pt-2 text-center">
        <div class="alert alert-primary alert-pills" role="alert">          
        <span class="badge badge-pill badge-yellow mr-1">So,
        </span>
        <span class="content">
          Do you need  <span class="my-color-1">Creative Assistant Online?</span></span
        >
      </div>


<br>   <br> 

      <h4 class="title mb-4">Work with our team <b>remotely from anywhere</b> and anytime</h4>
    

  </div>
  <!--end col-->




<div class="container">
<div class="row align-items-center">

  <div class="col-lg-5 col-md-6">
    <img 
      src="images/index/contracts.png"
      class="img-fluid"
      alt=""
    />
  </div>
  <!--end col-->



  <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
    <div class="section-title text-md-left text-center">

      <h5 class="my-color-2  font-weight-bold">
        Types of contracts :  
        <vue-typer
          :text="['Package Plan', 'Task Hour', 'Daily plan', 'Monthly Plan',  'Project Milestones', ]"
        ></vue-typer>
      </h5>
      

      <p class="text-muted mb-0 para-desc">
        It’s about understanding the internet <span v-b-tooltip.hover title="Karukaj best designer and search engine marketer's expert digital 
        marketing strategy energizes every website or social media with custom design expertise." 
        class="text-muted" >marketing challenges</span> behind your business
      </p>


     <p class="mt-3 h6 my-color-1 font-weight-bold           
"
        >How do we works ?</p>

                                      <p class="text-muted mb-0 para-desc">
        We work with passion to bring your ideas to life. We push ourselves. We push technology, And we push the boundaries of conventional thinking
      ..</p>

   <p class="mt-3 font-weight-bold my-color-2 ">  We believe: <span class="">Partner + Solution = Results</span> </p>


<h6 class="mt-3 h6 my-color-1 font-weight-bold"> Our Process of Work:</h6>

<p class="responsive-small mt-3">Consulting <i class="mdi mdi-chevron-right"></i> Strategy <i class="mdi mdi-chevron-right"></i> 
Design <i class="mdi mdi-chevron-right"></i> Development <i class="mdi mdi-chevron-right"></i> Marketing</p>




                
      <p class="mt-3 h6 my-color-1 font-weight-bold">Don't know where to start?</p>


      
      <ul class="responsive-small list-unstyled">
        <li>
          <span class="text-yellow h5 mr-2"><i class="uil uil-star align-middle"></i> </span>We can start a brainstorming free project storytelling
        </li>
        <li>
          <span class="text-yellow h5 mr-2"><i class="uil uil-star align-middle"></i> </span> Or, we'll take full responsibility for non-tech members.
        </li>
      </ul>




    </div>
  </div>
  <!--end col-->




</div>
<!--end row-->
</div>
<!--end container-->
















<br><br>


    <div class="container mb-4">





      <div class="row mb-4 justify-content-center">
          <div class="col-12">
            <div class="section-title text-center pb-2">
              <h4 class="title"><span class="font-weight-bold">Have a project in mind?</span> <br/>
              <small>we’d love to make your ideas into reality!</small></h4>              
            </div>
          </div>
          <!--end col-->
        </div>








      
        <div class="row align-items-center">






          <div
            class="col-lg-7 col-md-6 order-2 order-md-1 pt-2 mt-sm-0 pt-sm-0"
          >

          <div class="text-center">
              <img   width="80%" height="200" 
                src="images/clipart/index-project-mind.png"
                class="img-fluid"
                alt=""
              />
            </div>



            <p class="mt-20 text-muted para-desc">
                <span v-b-tooltip.hover title="Apart from graphics design and video production, Karukaj Digital is one of the best digital marketing 
agency in Bangladesh or best web development company in Bangladesh near Dhaka. We also popular as the best eCommerce website development company in Bangladesh." 
                class="text-muted" >Karukaj DIGITAL</span> offer a results-driven holistic marketing approach to increase your brand’s reach and dominate your competition.
              </p>
 

              <h4 class="mt-3 h6 font-weight-bold">What makes us unique?</h4>
              

              <ul class="responsive-small list-unstyled text-muted">
                 
              <li class="mb-0"> <span class="my-color-1 h5 mr-2">
                
                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

                
              </span>Ensure privacy and transparency in login & ownership.</li>


               <li class="mb-0"> <span class="my-color-1 h5 mr-2"><arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Offer world class technologies at your fingertips.</li>

              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Ensure simplicity and minimalistic UI/UX Design.</li>

              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Serve within time without compromising on quality.</li>

 
              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Create high impact dynamic visuals that sell anything.</li>


              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Always pay attention to brand's SEO and Storytelling.</li>


              <li class="mb-0"> <span class="my-color-1 h5 mr-2">                <arrow-right-circle-icon class="fea icon-sm my-color-1 mr-2"></arrow-right-circle-icon>

              </span>Focus on more traffic for mind-blowing possibilities.</li>


              </ul>

                            <div class="col-12 text-center">


                                <a href="javascript:void(0)" class="my-color-2 h6"><span v-b-tooltip.hover title="
                                  
                                  With other digital marketing service, our website design price in Bangladesh is very affordable.
                  In addition to custom redesigning old websites, Karukaj digital marketing agency is one of the 
                  best web development company in Bangladesh.
                                " 
                >Explore more than just “enjoy your stay!” </span> <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                            </div>


          </div>
          <!--end col-->




          <div class="col-lg-5 mt-4 col-md-6 order-1 order-md-2">

      
              


              <div class="card rounded bg-white shadow-lg border-0">
      <div class="card-body">

        <div class="text-center">

       <div class="mt-4 pt-2">
 
          <h4 class="title font-weight-bold">Create <span class="my-color-2 font-weight-bold">Free Account</span></h4>  
          <p class="text-secondary">If you wish to join inside our task management <br> members area to manage real-time project progress.</p>

     </div>
   </div>
   <!--end col--> 
           
           
           <iframe width="100%" height="520" src="https://business.karukaj.com.bd/forms/wtl/5344a22f754a486154add6b818665191" frameborder="0" allowfullscreen></iframe>


          </div>
              <!--end col-->
         

         </div>












  
          </div>
          <!--end col-->



        </div>
        <!--end row-->
      </div>
      <!--end container-->

























    <section
      class="section bg-white pt-0"
      :style="{
        background:
          'url(' +
          require('../../public/images/real/build2.png') +
          ') bottom no-repeat',
      }"
    >
  
      

















      <div class="container mt-100">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
       
              <div class="pt-2">
                <h4 class="text-secondary">So, are you the <b> kind of entrepreneur </b> who takes action?</h4>


                <router-link  class="btn btn-soft-light font-weight-bold mt-4 text-center" to="/explore"  v-b-tooltip.hover title="Stay with Good Works"   > <i class="mdi mdi-arrow-expand"></i>  EXPLORE MORE <i class="mdi mdi-chevron-right"></i> </router-link> 



                <div class="mt-120 mt-100 mt-40 text-center">  
                  
                
                
                



                  <br><br><br><br><br>



                
                
                </div>





















                
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <!--end container-->
    </section>
    <!--end section-->














    <!-- End -->
<div class="position-relative">
        <div class="shape overflow-hidden text-footer ">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#4e4e4e"></path>
            </svg>
        </div>
    </div>
    <!--end section-->

    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-primary btn-icon back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>





</template>
